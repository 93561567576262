<template>
  <div id="bg">
    <div class="search">
      <div class="search_box">
        上级分类:<el-select class="width_200" @change="getList()" v-model="search.type">
        <el-option  value="0,2,3" label="全部"></el-option>
        <el-option v-for="item of types" :key="item" :value="item.value" :label="item.label"></el-option>
      </el-select>
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="上级分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{ getType(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="启用" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch :active-value=1 :inactive-value=0 @click="enableSwitch(scope.row)" :value="scope.row.enable" ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)" >删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">标题</el-col>
        <el-col :span="20">
          <el-input placeholder="请输入标题" v-model="dialogAdd.title"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">上级分类</el-col>
        <el-col :span="20">
          <el-select class="width_200" v-model="dialogAdd.type">
            <el-option v-for="item of types" :key="item" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">权重</el-col>
        <el-col :span="20">
          <el-input type="number" v-model="dialogAdd.weight"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">启用</el-col>
        <el-col :span="20">
          <el-switch :active-value=1 :inactive-value=0 @click="dialogAdd.enable==0?dialogAdd.enable =1:dialogAdd.enable=0" :value="dialogAdd.enable"></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {typeList, typeAdd,typeEdit, typeStatus,typeDel} from "@/api/community/type.js"
import page from "@/components/page";
export default {
  name: "community_type",
  components: {
    page,
  },
  data(){
    return {
      search:{
        type:"0,2,3",
      },
      pager: { total: 0, page: 1, rows: 50 },
      tableData:[
      ],
      dialogVisible:false,
      dialogTitle:"添加分类",
      dialogAdd:{
        id:0,
        title:"",
        enable:0,
        type:null,
        weight:0
      },
      current_row:null,// 编辑的当前行
      types:[
        {label:"漫画",value:0},
        {label:"小说",value:2},
        {label:"听书",value:3},
      ],
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getType(type){
      console.log("分类:",type)
      for(const t of this.types){
        if (type == t.value){
          return t.label
        }
      }
      return ""
    },
    del(row){
      this.$confirm("删除分类: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        typeDel([{key:"id",val:row.id}]).then((res)=>{
          if(res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    enableSwitch(row){
      typeStatus([
        {key:"id",val:row.id},
        {key:"enable",val:row.enable == 0?1:0},
      ]).then((res)=>{
        if (res.code == 0){
          row.enable = row.enable == 0?1:0
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$message({message:err,type:"error"})
      })
    },
    getList(){
      typeList([
        {key:"page",val:this.pager.page},
        {key:"row",val:this.pager.rows},
        {key:"type",val:this.search.type},
      ]).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      },(err)=>{
        console.log(err)
      })
    },
    showDialog(row){
      this.dialogVisible = true
      this.dialogAdd = {
        id:0,
        title:"",
        type:null,
        enable:0,
        weight:0
      }
      if (row){
        this.current_row = row
        this.dialogTitle = "编辑分类,ID:"+row.id
        this.dialogAdd = {
          id:row.id,
          title:row.title,
          enable:row.enable,
          type:row.type,
          weight:row.weight
        }
      }else{
        this.dialogTitle = "添加分类"
      }
    },
    save(){
      // 保存
      if(this.dialogAdd.title == ""){
        this.$message({ message: "请输入分类的标题", type: "error" });
        return;
      }
      if(this.dialogAdd.type == null){
        this.$message({ message: "请选择分类类型", type: "error" });
        return;
      }
      if (this.dialogAdd.id == 0){
        // 添加
        typeAdd([
          {key:"title",val:this.dialogAdd.title},
          {key:"enable",val:this.dialogAdd.enable},
          {key:"type",val:this.dialogAdd.type},
          {key:"weight",val:this.dialogAdd.weight},
        ]).then((res)=>{
          if (res.code == 0){
            this.dialogVisible = false
            this.$message({ message: "分类添加成功", type: "success" });
            this.getList()
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      }else{
        // 修改
        typeEdit([
          {key:"id",val:this.dialogAdd.id},
          {key:"title",val:this.dialogAdd.title},
          {key:"enable",val:this.dialogAdd.enable},
          {key:"type",val:this.dialogAdd.type},
          {key:"weight",val:this.dialogAdd.weight},
        ]).then((res)=>{
          if (res.code == 0){
            this.dialogVisible = false
            this.$message({ message: "分类修改成功", type: "success" });
            for(var key in this.dialogAdd){
              this.current_row[key] = this.dialogAdd[key]
            }
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      }
    }
  }
}
</script>

<style scoped>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>